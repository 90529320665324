import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';

export default {
    name: "Footprint",
    mounted () {
        document.title = "AGC - Footprint";

        AOS.init();
        resize();
        function resize(){
            if(window.innerWidth<800) $('.footer').css({'position':'relative','top':'0px'});
        }
    },
    components: {
        Footer,Header
    }
};